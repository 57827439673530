import { H1 } from '@slc/ui'

import { i18nConfig } from '@slc/i18n'
import { Trans } from '@lingui/macro'
import { hasCreditAccess, hasQuoteAccess } from '@slc/user'
import { CustomerDashboard } from '@/views/dashboard/customer'
import { PremiumDashboard } from '@/views/dashboard/premium'
import { ResellerDashboard } from '@/views/dashboard/reseller'

function Content() {
  if (!hasCreditAccess()) {
    return <CustomerDashboard />
  }

  return hasQuoteAccess() ? <ResellerDashboard /> : <PremiumDashboard />
}

export default function DashboardPage() {
  return (
    <>
      <H1>
        <Trans id="page.customer.dashboard.title" />
      </H1>

      <Content />
    </>
  )
}

export async function getServerSideProps(ctx) {
  return {
    props: await i18nConfig(ctx),
  }
}
