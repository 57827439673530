import Grid from '@material-ui/core/Grid'
import { H2, FetchableContent } from '@slc/ui'

import first from 'lodash/first'

import { Trans } from '@lingui/macro'
import { Table, TableLoader, TableRows } from '@/components/Table'

export function CustomerDashboard() {
  return (
    <Grid container spacing={2}>
      <H2>
        <Trans id="customer.views.dashboard.customer.lastOrder.title" />
      </H2>

      <Table>
        <FetchableContent
          fetchInfos="/api/customer/threads/messageOrders"
          Renderer = {
            ({ data }) => <TableRows rows={data.length ? [first(data)] : []} />
          }
          Loader={() => <TableLoader />}
        />
      </Table>

      {/* <H2>
        <Trans id='customer.views.dashboard.customer.informations.title' />
      </H2> */}
    </Grid>
  )
}
