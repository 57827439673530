import React from 'react'
import { FetchableContent } from '@slc/ui'
import { Card } from '@/components/Card'
import { Table, TableLoader, TableRows } from '@/components/Table'
import { getCustomerLinkHref } from '@slc/app-links'

import { Trans } from '@lingui/macro'

export const LastOrdersCard = () => (
  <Card
    title={
      <Trans id='customer.views.dashboard.card.lastOrders.title' >
        Les dernières commandes
      </Trans >
    }
    links={
      [
        {
          href: getCustomerLinkHref('orders'),
          content: (
            <Trans id='customer.views.dashboard.card.lastOrders.link'>
              Consulter les commandes
            </Trans>
          )
        }
      ]}
  >
    <Table>
      <FetchableContent
        fetchInfos='/api/customer/threads/orders'
        Renderer={({ data }) => (
          <TableRows rows={data} />
        )}
        Loader={() => <TableLoader />}
      />
    </Table>
  </Card>
)
