import Grid from '@material-ui/core/Grid'

import { CreditCard } from '../components/CreditCard'
import { LastOrderMessagesCard } from '../components/LastOrderMessagesCard'
import { LastOrdersCard } from '../components/LastOrdersCard'

export const PremiumDashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={10}>
        <CreditCard />
      </Grid>

      <Grid item xs={12} md={10}>
        <LastOrderMessagesCard />
      </Grid>

      <Grid item xs={12} md={10}>
        <LastOrdersCard />
      </Grid>
    </Grid>
  )
}
