import React from 'react'
import { Card } from '@/components/Card'
import { Loader, FetchableContent } from '@slc/ui'
import { getCustomerLinkHref } from '@slc/app-links'

import get from 'lodash/get'
import { Trans } from '@lingui/macro'

export const CreditCard = () => (
  <Card
    title={
      <Trans id='customer.views.dashboard.card.credit.title'>Vos crédits</Trans>
    }
    links={[
      {
        href: getCustomerLinkHref('statements'),
        content: (
          <Trans id='customer.views.dashboard.card.credit.statements.link'>Historique</Trans>
        )
      },
      {
        href: getCustomerLinkHref('topup'),
        content: (
          <Trans id='customer.views.dashboard.card.credit.credit.link'>Créditer</Trans>
        )
      }
    ]}
  >
    <FetchableContent
      fetchInfos='/api/customer/balance'
      Renderer={({ data }) => {
        const credits = get(data, 'balance', 0)

        return (
          <Trans id='customer.views.dashboard.card.credit.label' values={{ credits }}>Votre solde s'élève à {credits} credits.</Trans>
        )
      }}
      Loader={Loader}
    />
  </Card>
)
