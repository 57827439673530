import React from 'react'
import MuiCard from '@material-ui/core/Card'
import MuiCardActions from '@material-ui/core/CardActions'
import MuiCardContent from '@material-ui/core/CardContent'
import MuiCardHeader from '@material-ui/core/CardHeader'

import { LinkButton } from '@slc/ui'

export function Card({ title, links = [], children }) {
  return (
    <MuiCard>
      <MuiCardHeader title={title} />
      <MuiCardContent>{children}</MuiCardContent>
      <MuiCardActions>
        {links.map(({ href, content }) => (
          <LinkButton href={href} key={href}>
            {content}
          </LinkButton>
        ))}
      </MuiCardActions>
    </MuiCard>
  )
}
