import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiTable from '@material-ui/core/Table'
import MuiTableBody from '@material-ui/core/TableBody'
import MuiTableCell from '@material-ui/core/TableCell'
import MuiTableContainer from '@material-ui/core/TableContainer'
import MuiTableHead from '@material-ui/core/TableHead'
import MuiTableRow from '@material-ui/core/TableRow'
import ArrowRight from '@material-ui/icons/ArrowRight'

import { formatDate, formatPrice } from '@slc/utils'
import { Loader, IconButton, Link } from '@slc/ui'

import {
  getMessageStatus,
  getMessageCategory,
  getMessageType,
  getOrderType,
  getOrderStatus,
} from '@slc/data-i18n'

import truncate from 'lodash/truncate'

import { Trans } from '@lingui/macro'
import { getCustomerLinkHref, links } from '@slc/app-links'

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
})

export function TableOrderRenderer({ data }) {
  return (
    <MuiTableRow key={data.ref}>
      <MuiTableCell scope="data">{formatDate(data.when)}</MuiTableCell>
      <MuiTableCell align="left">
        <Trans id={getOrderType(data.type)} />
      </MuiTableCell>
      <MuiTableCell align="left">{data.description || data.order}</MuiTableCell>
      <MuiTableCell align="right">{formatPrice(data.total)}</MuiTableCell>
      <MuiTableCell align="right">
        <Trans id={getOrderStatus(data.status)} />
      </MuiTableCell>
      <MuiTableCell align="right">
        <Link
          href={getCustomerLinkHref(links.customer.order, { oid: data.oid })}
        >
          <IconButton Icon={() => <ArrowRight color="primary" />} />
        </Link>
      </MuiTableCell>
    </MuiTableRow>
  )
}

export function TableMessageRenderer({ data }) {
  return (
    <MuiTableRow key={data.ref}>
      <MuiTableCell scope="data">{formatDate(data.when)}</MuiTableCell>
      <MuiTableCell align="left">
        <Trans id={getMessageCategory(data.category)} />
      </MuiTableCell>
      <MuiTableCell align="right">
        <Trans id={getMessageType(data.type)} />
      </MuiTableCell>
      <MuiTableCell align="left">
        <Trans id={truncate(data.selection, 80)} />
      </MuiTableCell>
      <MuiTableCell align="right">
        <Trans id={getMessageStatus(data.status)} />
      </MuiTableCell>
      <Link href={getCustomerLinkHref(links.customer.order, { oid: data.oid })}>
        <IconButton Icon={() => <ArrowRight color="primary" />} />
      </Link>
    </MuiTableRow>
  )
}

export function TableRows({ rows = [], Renderer = TableOrderRenderer }) {
  return (
    <MuiTableBody>
      {!rows.length && (
        <MuiTableRow>
          <MuiTableCell scope="row" colSpan={5} align="center">
            <Trans id="customer.views.dashboard.card.table.empty" />
          </MuiTableCell>
        </MuiTableRow>
      )}
      {rows.map((row, i) => (
        <Renderer key={i} data={row} />
      ))}
    </MuiTableBody>
  )
}

export function TableLoader() {
  return (
    <MuiTableBody>
      <MuiTableRow>
        <MuiTableCell colSpan="5" align="center">
          <Loader />
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableBody>
  )
}

export function TableOrderHeader() {
  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell align="left">
          <Trans id="customer.views.dashboard.card.table.date">Date</Trans>
        </MuiTableCell>
        <MuiTableCell align="left">
          <Trans id="customer.views.dashboard.card.table.type">Type</Trans>
        </MuiTableCell>
        <MuiTableCell align="left">
          <Trans id="customer.views.dashboard.card.table.description">
            Description
          </Trans>
        </MuiTableCell>
        <MuiTableCell align="right">
          <Trans id="customer.views.dashboard.card.table.amount">Montant</Trans>
        </MuiTableCell>
        <MuiTableCell align="right">
          <Trans id="customer.views.dashboard.card.table.status">Status</Trans>
        </MuiTableCell>
        <MuiTableCell />
      </MuiTableRow>
    </MuiTableHead>
  )
}

export function TableMessageHeader() {
  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell align="left">
          <Trans id="customer.views.dashboard.card.table.date">Date</Trans>
        </MuiTableCell>
        <MuiTableCell align="left">
          <Trans id="customer.views.dashboard.card.table.category">
            Categorie
          </Trans>
        </MuiTableCell>
        <MuiTableCell align="right">
          <Trans id="customer.views.dashboard.card.table.type">Type</Trans>
        </MuiTableCell>
        <MuiTableCell align="left">
          <Trans id="customer.views.dashboard.card.table.description">
            Description
          </Trans>
        </MuiTableCell>
        <MuiTableCell align="right">
          <Trans id="customer.views.dashboard.card.table.status">Status</Trans>
        </MuiTableCell>
        <MuiTableCell />
      </MuiTableRow>
    </MuiTableHead>
  )
}

export function Table({
  title,
  rows,
  children,
  loading = false,
  TableHeader = TableOrderHeader,
  TableRowRenderer,
}) {
  const classes = useStyles()

  return (
    <MuiTableContainer>
      <MuiTable elevation={0} className={classes.table}>
        {TableHeader && <TableHeader />}
        {loading && <TableLoader />}
        {rows && <TableRows rows={rows} Renderer={TableRowRenderer} />}
        {children}
      </MuiTable>
    </MuiTableContainer>
  )
}
