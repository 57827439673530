import Grid from '@material-ui/core/Grid'

import { QuoteCard } from '../components/QuoteCard'
import { CreditCard } from '../components/CreditCard'
import { LastOrdersCard } from '../components/LastOrdersCard'
import { LastOrderMessagesCard } from '../components/LastOrderMessagesCard'

export const ResellerDashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={10}>
        <CreditCard />
      </Grid>

      <Grid item xs={12} md={10}>
        <QuoteCard />
      </Grid>
      <Grid item xs={12} md={10}>
        <LastOrderMessagesCard />
      </Grid>

      <Grid item xs={12} md={10}>
        <LastOrdersCard />
      </Grid>
    </Grid>
  )
}
