import React from 'react'
import { FetchableContent } from '@slc/ui'
import { Card } from '@/components/Card'
import { Table, TableLoader, TableRows } from '@/components/Table'
import { getCustomerLinkHref } from '@slc/app-links'

import { Trans } from '@lingui/macro'

export const LastOrderMessagesCard = () => (
  <Card
    title={
      <Trans id='customer.views.dashboard.card.lastOrderMessages.title'>
        Les dernières commandes de messages
      </Trans>
    }
    links={[
      {
        href: getCustomerLinkHref('orders'),
        content: (
          <Trans id='customer.views.dashboard.card.lastOrderMessages.link'>
            Consulter les commandes
          </Trans>
        )
      }
    ]}
  >
    <Table>
      <FetchableContent
        fetchInfos='/api/customer/threads/messageOrders'
        Renderer={({ data }) => (
          <TableRows rows={data} />
        )}
        Loader={() => <TableLoader />}
      />
    </Table>
  </Card>
)
